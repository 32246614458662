import '../css/app.css';

/**
 * Pallet Production Manager - Main JS Entry Point
 * -----------------------------------------
 * This file is processed by Vite during the build process.
 * It imports CSS and initializes any global JavaScript functionality.
 */

// Run initialization code when the DOM is ready
document.addEventListener('DOMContentLoaded', function() {
    console.log('Pallet Production Manager initialized');
    
    // Add any global initialization code here
    // For example, initializing third-party libraries loaded via CDN
    
    // Initialize CSRF protection for AJAX requests
    setupCSRFProtection();
    
    // Initialize any global UI components
    setupUIComponents();
});

/**
 * Setup CSRF Protection for AJAX requests
 */
function setupCSRFProtection() {
    // Get the CSRF token from the meta tag
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    
    // If using jQuery AJAX, configure it to always send the CSRF token
    if (window.jQuery) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token
            }
        });
    }
    
    // If using fetch API directly
    window.fetchWithCSRF = function(url, options = {}) {
        if (!options.headers) {
            options.headers = {};
        }
        
        options.headers['X-CSRF-TOKEN'] = token;
        return fetch(url, options);
    };
}

/**
 * Setup UI Components
 */
function setupUIComponents() {
    // Initialize any global UI components or behaviors here
    // This is just a placeholder for your custom code
}

